import { Divider, Flex, Grid, GridItem, Heading, Text } from '@chakra-ui/react';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React from 'react';
import BlogPreview from '../../components/blog/blog-preview';
import SEO from '../../components/seo';
import slugify from '../../utils/slugify';

function BlogPage({ data }) {
  const seoTitle = data.pageSeo.edges[0].node.pageName;
  const seoDescription =
    data.pageSeo.edges[0].node.description.internal.content;
  return (
    <>
      <SEO title={seoTitle} description={seoDescription} />
      <Flex
        flexDir="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        w="100%"
        mt={{ base: 8, lg: 16 }}
        mb={{ base: 8, lg: 16 }}
      >
        <Heading>Blog</Heading>
        <Text mt={3} color="gray.600" w={{ base: '100%', lg: '60%' }}>
          We post regularly with tips and advice for managing children and
          adolescents. Subscribe to our mailing list to receive them direct to
          your inbox!
        </Text>
        <Divider my={8} />
        <Grid templateColumns={{ base: '1fr', lg: 'repeat(3, 1fr)' }} gap={8}>
          {data.posts.edges.map((edge) => {
            const slug = slugify(edge.node.title);
            const author = edge.node.postAuthor
              ? edge.node.postAuthor
              : { fullName: 'Parentshop Staff' };
            return (
              <GridItem w="100%">
                <BlogPreview
                  title={edge.node.title}
                  tags={edge.node.tags}
                  author={author.fullName}
                  // TODO: remove
                  imageUrl={getImage(edge.node.image?.gatsbyImage) || null}
                  url={`blog/${slug}`}
                  excerpt={edge.node.excerpt.excerpt}
                  date={edge.node.datePublished}
                  external={false}
                  alt={edge.node.image?.title || null}
                />
              </GridItem>
            );
          })}
        </Grid>
      </Flex>
    </>
  );
}

export default BlogPage;

export const query = graphql`
  query {
    pageSeo: allContentfulPageSeo(filter: { pageName: { eq: "blog" } }) {
      edges {
        node {
          pageName
          description {
            internal {
              content
            }
          }
        }
      }
    }
    posts: allContentfulBlogPost(sort: { fields: datePublished, order: DESC }) {
      edges {
        node {
          slug
          postAuthor {
            fullName
          }
          datePublished(formatString: "MMMM D, yyyy")
          image {
            gatsbyImage(width: 300)
          }
          title
          tags
          excerpt {
            excerpt
          }
        }
      }
    }
  }
`;
